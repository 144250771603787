import { SubscriptionDetails } from './subscription';

export enum Status {
  GUEST = 'guest',
  NEW = 'new',
  AWAITING_REVIEW = 'awaiting_review',
  APPROVED = 'approved',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  PAUSED = 'paused',
  INACTIVE = 'inactive',
  REJECTED = 'rejected',
  WAITLIST = 'waitlist',
  FORM = 'form',
}

export enum Region {
  USA = 'USA',
  LATAM = 'LATAM',
  UK = 'UK',
  Portugal = 'Portugal',
  EU = 'EU',
  Other = 'Other',
}

export type CommonUserDto = {
  _id: string;
  userType: UserType;
  email: string;
  lastLogin: Date;
  createdAt: Date;
  updatedAt: Date | null;
};

export type Member = CommonUserDto & {
  status: Status;
  brazeId: string;
  reApplyMessage: string;
  customerId: string;
  referralCode: string;
  successfulReferrals: number;
  experienceBookingsCount: number;
  experienceBookingsCountLast3Month: number;
  checkinsCount: number;
  checkinsCountLast3Month: number;
  onboardingSettings: OnboardingSettings;
  profile: Partial<MemberProfile>;
  subscription: SubscriptionDetails;
  location: number[];
};

export type MemberProfile = {
  firstName: string;
  lastName: string;
  birthday: string;
  phoneNumber: string;
  gender: Gender;
  region: Region;
  countryOfResidence: string;
  secondaryCities: string[];
  industry: string;
  linkedin: string;
  instagram: string;
  travelStory: string;
  aboutMe: string;
  perfectDay: string;
  fuckMarryKillCities: string;
  excitesyou: string[];
  avatar: string;
  photos: string[];
  step: number;
  profession: string;
  passions: string[];
  currentCity: string;
  nextCity: string;
  isPrivate: boolean;
  tags: Tag[];
  //trips: Trip[];
  viewedGuides: string[];
  //contactPreferences: ContactPreferencesEnum[];
  dietaryRestrictions: string[];
  primaryPaymentMethodId: string;
  inHousePaymentMethodId: string;
  receiveMarketingMessages: boolean;
  mediaUsagePermission: boolean;
  suggestedMembers: string[];
  ad_name?: string;
};

export enum Gender {
  HE = 'HE/HIM',
  SHE = 'SHE/HER',
  THEY = 'THEY/THEM',
  NO = 'I PREFER NOT TO SAY',
}

export enum UserType {
  MEMBER = 'member',
  PARTNER = 'partner',
  ADMIN = 'admin',
  GUEST = 'guest',
  PUBLIC = 'public',
}

export enum HowYouKnowAboutUs {
  REFERRAL = 'referral',
  YAYEM_EXPERIENCE = 'yayem_experience',
  AETHOS_EXPERIENCE = 'aethos_experience',
  INSTAGRAM = 'instagram',
  OTHER = 'other',
}

export type OnboardingSettings = {
  referredBy: Pick<Member, '_id' | 'referralCode' | 'email'>;
  expediteCode: string;
  promoCode: string;
  expediteBy: Expedite;
  howYouKnowAboutUs: HowYouKnowAboutUs;
  otherText?: string;
};

export type Expedite = {
  expediteReferralCode: string;
  email: string;
};

export type Subscriptions = {
  priceId: string;
  productId: string;
  productName: string;
  status: string;
};

export enum CommunityManagmentFilters {
  MOST_RECENT_APPLICAITONS = 'Most Recent Applications',
  NEWEST_MEMBERS = 'Newest Members',
  LOW_EVENT_PARTICIPATION = 'Churn Risk: Low Event Participation',
  LOW_SPACE_UTILIZATION = 'Churn Risk: Low Space Utilization',
  EXCEED_LISBON_CHECKINS = 'Lisbon 12+ (Global)',
}

export type Trip = {
  _id?: string;
  title: string;
  startDate: Date;
  endDate: Date;
};

export type Tag = {
  category: string;
  key: string;
  value: string;
};

export type SuggestedMemberProfile = {
  firstName: string;
  lastName: string;
};

export type SuggestedMemberDto = {
  _id: string;
  status: Status;
  email: string;
  profile: SuggestedMemberProfile;
};
