import { AccessTypeEnum, AccessibilityTypeEnum, OperatorEnum } from 'models/experiences';

export const experiencesTagsOptions = [
  {
    value: 'bar',
    label: 'BAR',
  },
  {
    value: 'breakfast',
    label: 'BREAKFAST',
  },
  {
    value: 'culture',
    label: 'CULTURE',
  },
  {
    value: 'digital',
    label: 'DIGITAL',
  },
  {
    value: 'dinner',
    label: 'DINNER',
  },
  {
    value: 'dog-friendly',
    label: 'DOG-FRIENDLY',
  },
  {
    value: 'drinks',
    label: 'DRINKS',
  },
  {
    value: 'field trip',
    label: 'FIELD TRIP',
  },
  {
    value: 'fitness',
    label: 'FITNESS',
  },
  {
    value: 'food',
    label: 'FOOD',
  },
  {
    value: 'good for work',
    label: 'GOOD FOR WORK',
  },
  {
    value: 'growth',
    label: 'GROWTH',
  },
  {
    value: 'hotel',
    label: 'HOTEL',
  },
  {
    value: 'laptop friendly',
    label: 'LAPTOP FRIENDLY',
  },
  {
    value: 'lunch',
    label: 'LUNCH',
  },
  {
    value: 'music',
    label: 'MUSIC',
  },
  {
    value: 'restaurant',
    label: 'RESTAURANT',
  },
  {
    value: 'spirituality',
    label: 'SPIRITUALITY',
  },
  {
    value: 'trip',
    label: 'TRIP',
  },
  {
    value: 'wellness',
    label: 'WELLNESS',
  },
  {
    value: 'workshop',
    label: 'WORKSHOP',
  },
  {
    value: 'flagship',
    label: 'FLAGSHIP',
  },
  {
    value: 'yhump',
    label: 'YHUMP',
  },
];

export const accessibilityOptions = [
  { value: AccessibilityTypeEnum.HYBRID, label: 'Hybrid' },
  { value: AccessibilityTypeEnum.IN_PERSON, label: 'In person' },
  { value: AccessibilityTypeEnum.TRIP, label: 'Trip' },
  { value: AccessibilityTypeEnum.VIRTUAL, label: 'Virtual' },
];

export const accessTypeOptions = [
  { value: AccessTypeEnum.PRIVATE, label: 'Members & guests' },
  { value: AccessTypeEnum.PUBLIC, label: 'Public' },
];

export const operatorsOptions = [
  { value: OperatorEnum.AETHOS, label: 'Aethos' },
  { value: OperatorEnum.PARTNER, label: 'Partner' },
];
